<template>
  <div>
    <TitleBar
      :top-title="title"
      :top-left="'back'"
      :continual="false"
    />
    <div class="flex_scroll">
      <ul class="menu_list">
        <li>
          {{ getLang('메인 페이지 유형') }}
        </li>
        <ul
          class="childConts"
          style="overflow:unset"
        >
          <li>
            <i class="setting" />
            <div class="childName">
              <select
                v-model="main_tp"
                style="margin-top:-7px; background-color:inherit; color:inherit; font-weight:bold"
                @change="onChangeMainTP()"
              >
                <option
                  v-for="el in main_tp_list"
                  :key="el.cate_a"
                  :value="el.cate_a"
                >
                  {{ getLang(el.nm) }}
                </option>
              </select>
            </div>
          </li>
        </ul>
        <li
          style="position:relative"
        >
          {{ getLang('언어 선택') }}
        </li>
        <ul
          class="childConts"
          style="overflow:unset"
        >
          <li>
            <i class="setting" />
            <div class="childName">
              <select
                v-model="lang_tp"
                style="margin-top:-7px; background-color:inherit; color:inherit; font-weight:bold"
                @change="onChangeLang()"
              >
                <option
                  v-for="el in langSelect"
                  :key="el[0]"
                  :value="el[0]"
                >
                  {{ el[1] }}
                </option>
              </select>
            </div>
          </li>
        </ul>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TitleBar from '@/components/navi/TitleBar.vue';
import axios from 'axios';

export default {
  metaInfo () {
    return {
      title: this.title,
    };
  },
  components: {
    TitleBar,
  },
  data () {
    return {
      lang_tp: this.$store.state.lang_tp,
      main_tp: this.$store.state.main_tp,
      main_tp_list: [{
        cate_a: '*',
        nm: '기본 메뉴'
      }],
      isShowMsgBoard: false,
      ret_needs_cnt: 0,
      ret_needs: '',
      ret_err_cnt: 0,
      ret_err: '',
      ret_success_cnt: 0,
      ret_success: '',
    };
  },
  computed: {
    ...mapGetters({
      getLang: 'getLang'
    }),
    title () {
      return this.getLang('설정');
    },
  },
  async mounted () {
    await this.loadBasic();

    const state = this.$store.state;

    this.lang_tp = state.lang_tp;
    this.main_tp = state.main_tp;

    state.cateInfo.filter((el) => !el.parent_uid && el.cate_uid != 'myCate').sort((a, b) => a.cate_uid - b.cate_uid).forEach((el) => {
      this.main_tp_list.push({
        cate_a: el.cate_uid,
        nm: el.cate_name,
      });
    });
  },
  methods: {
    ...mapActions({
      loadBasic: 'loadBasic',
    }),
    async onChangeMainTP () {
      await axios.post(`${this.$store.state.apiHost}/api/setConfig`, {
        params: { main_tp: this.main_tp }
      });

      this.loadBasic(true);
    },
    async onChangeLang () {
      await axios.post(`${this.$store.state.apiHost}/api/setConfig`, {
        params: { lang_tp: this.lang_tp, main_tp: '*' }
      });

      location.reload();
    },
  }
};
</script>

<style scoped>
  .menu_list {
    margin:0; font-size:1.4rem; font-weight:bold
  }
  .menu_list>li {
    padding:11px 30px; margin-bottom:1px; background-color:#aaa; color:#fff
  }
  .childConts {
    position:relative; margin:15px 8px; padding-left:20px; overflow:hidden
  }
  .childConts>li>i {
    filter:invert(1); width:25px; height:25px
  }
  .childConts>li>.childName {
    position:absolute; margin:-22px 0 0 35px
  }
  .childConts>div>li {
    float:right;
    margin:1px;
    padding:3px;
    width:27px;
    font-size:1.1rem;
    color:#fff;
    text-align:center;
    transition:all 0.3s ease;
    cursor:pointer;
  }
</style>
